<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>

            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Partner landing list</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- id -->
                                    <th class="table-header-title" @click="toggleSort('id')">
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>

                                    <!--  landing title  -->
                                    <th class="table-header-title search-column" @click="toggleSort('landingTitle')">
                                        landing title
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('landingTitle', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('landingTitle', 'asc')"
                                        />
                                    </th>

                                    <!-- domain -->
                                    <th class="table-header-title search-column" @click="toggleSort('domain')">
                                        domain
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('domain', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('domain', 'asc')"
                                        />
                                    </th>

                                    <!-- created -->
                                    <th class="table-header-title" @click="toggleSort('created_at')">
                                        created
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(domain, index) in domains" :key="domain.id">
                                    <th scope="row">{{ index }}</th>
                                    <td>{{ domain.id }}</td>
                                    <td>{{ domain.landingTitle ?? '-' }}</td>
                                    <td>{{ domain.domain }}</td>
                                    <td>{{ formatDate(domain.created_at) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MasterView from '@/views/Admin/MasterView.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import api from '@/api'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import Pagination from '@/components/Layouts/PaginationPanel.vue'

export default {
    name: 'PartnerDomainList',
    components: { Pagination, MessageItem, FooterPanel, MasterView },
    data() {
        return {
            domains: [],
            perPage: 10,
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            searchObject: {
                text: '',
                active: false,
            },
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
        isSearchActive() {
            return this.searchObject.active
        },
    },
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        fetchDomains() {
            api.loadPartnerDomains({ landing: true }).then((response) => {
                if (response.status === 200) {
                    this.domains = response.data.data.partnerDomains
                }
            })
        },
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('partnerClientSearch')
            this.loadPartnerClientList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
        changePage(currentPage) {
            localStorage.setItem('fastRegCurrentPage', currentPage)
            this.loadPartnerClientList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: currentPage,
            })
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            if (this.isTrashListComponent) {
                return date.toLocaleDateString()
            } else {
                return date.toLocaleString()
            }
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        onChangePerPage() {
            localStorage.setItem('perPage', this.perPage)
            this.loadPartnerClientList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('partnerClientSearch', JSON.stringify(this.searchObject))
                this.loadPartnerClientList({
                    orderBy: this.orderBy,
                    perPage: this.perPage,
                    search: this.searchObject.text,
                    status: this.status,
                    currentPage: this.currentPage,
                })
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('partnerClientOrderBy', JSON.stringify(this.orderBy))
            this.loadPartnerClientList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
    },
    created() {
        this.fetchDomains()
    },
}
</script>
