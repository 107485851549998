<template>
    <master-view>
        <remove-modal :modal="removeModal" @cancel="removeModal.reset" @agree="removeUser" />
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>List users</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- id -->
                                    <th class="table-header-title" @click="toggleSort('id')">
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>
                                    <!-- name -->
                                    <th class="table-header-title" @click="toggleSort('name')">
                                        name
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('name', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('name', 'asc')"
                                        />
                                    </th>
                                    <!-- email -->
                                    <th class="table-header-title col-2" @click="toggleSort('email')">
                                        email
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('email', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('email', 'asc')"
                                        />
                                    </th>
                                    <!-- roles -->
                                    <th class="table-header-title col-4">roles</th>
                                    <!-- created_at -->
                                    <th class="table-header-title" @click="toggleSort('created_at')">
                                        created_at
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(user, index) in getUsers.items" :key="user.id">
                                    <th scope="row">{{ getAmountNumber(index) }}</th>
                                    <td>{{ user.id }}</td>
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td class="permissions-cell">
                                        <span
                                            v-for="(role, order) in getRoles(user)"
                                            :key="order"
                                            class="role-item permission-tag"
                                        >
                                            {{ role }}
                                        </span>
                                    </td>
                                    <td>{{ formatDate(user.created_at) }}</td>
                                    <td>
                                        <router-link
                                            :to="{ name: 'UserEdit', params: { id: user.id } }"
                                            v-if="isEditable(user)"
                                        >
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon" />
                                        </router-link>
                                        <a href="#" v-else>
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon-disable" />
                                        </a>

                                        <font-awesome-icon
                                            v-if="isEditable(user)"
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon"
                                            @click="removeItem(user.id)"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon-disable"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import Pagination from '@/components/Layouts/PaginationPanel.vue'
import RemoveModal from '@/components/Modals/RemoveModal.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/store/user'
import api from '@/api'
import user from '@/mixins/user'

export default {
    name: 'UserList',
    components: { FooterPanel, MasterView, RemoveModal, Pagination },
    computed: {
        ...mapState(useUserStore, ['getUsers', 'getUser', 'getPagination']),
        isSearchActive() {
            return this.searchObject.active
        },
    },
    data() {
        return {
            perPage: 10,
            activeMenuItem: [],
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            removeModal: {
                show: false,
                title: null,
                text: null,
                id: null,
                reset: () => {
                    this.removeModal.title = null
                    this.removeModal.text = null
                    this.removeModal.show = false
                },
            },
            searchObject: {
                text: '',
                active: false,
            },
            type: '',
            currentPage: null,
        }
    },
    mixins: [user],
    methods: {
        ...mapActions(useUserStore, ['loadUsers']),
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('userSearch')
            this.loadUsers({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        changePage(currentPage) {
            localStorage.setItem('userCurrentPage', currentPage)
            this.loadUsers({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: currentPage,
            })
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        isEditable(user) {
            const currentUser = this.getUser
            if (!currentUser?.roles?.length) return false

            const currentUserRole = currentUser.roles[0].slug
            const userRole = user.roles[0]?.slug

            const roleHierarchy = {
                superadmin: ['superadmin', 'supervisor', 'user'],
                supervisor: ['supervisor', 'user'],
                user: [],
            }

            if (currentUserRole === 'superadmin') return true
            if (!userRole) return currentUserRole !== 'user'

            return roleHierarchy[currentUserRole]?.includes(userRole) || false
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        getRoles(user) {
            return user.roles.map((role) => role.slug)
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            return date.toLocaleString()
        },
        load() {
            const perPage = localStorage.getItem('userPerPage')
            const orderBy = localStorage.getItem('userOrderBy')
            const searchLanding = JSON.parse(localStorage.getItem('userSearch'))
            const currentPage = localStorage.getItem('userCurrentPage')
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchLanding && searchLanding.active) {
                this.searchObject.active = searchLanding.active
                this.searchObject.text = searchLanding.text
            }
            if (currentPage) {
                this.currentPage = currentPage
            }
            this.loadUsers({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        onChangePerPage() {
            localStorage.setItem('userPerPage', this.perPage)
            this.loadUsers({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        removeUser(user) {
            api.removeUser(user.id).then((response) => {
                if (204 === response.status) {
                    this.removeModal.reset()
                    this.load()
                }
            })
        },
        removeItem(id) {
            this.removeModal.title = 'Remove user'
            this.removeModal.text = 'Do you want remove this user?'
            this.removeModal.show = true
            this.removeModal.id = id
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('userSearch', JSON.stringify(this.searchObject))
                this.loadUsers({
                    orderBy: this.orderBy,
                    perPage: this.perPage,
                    search: this.searchObject.text,
                    currentPage: this.currentPage,
                })
            }
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('userOrderBy', JSON.stringify(this.orderBy))
            this.loadUsers({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
    },
    created() {
        this.load()
    },
    beforeRouteUpdate(to, from, next) {
        if (from.name === to.name) {
            this.load()
        }
        next()
    },
}
</script>
