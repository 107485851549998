<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Partner domain create</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-12">
                                <label class="form-field-title">Domain url <span class="required">*</span></label>
                                <div class="d-flex mt-3">
                                    <input type="text" class="form-control" v-model="domain.url" />
                                </div>
                            </div>

                            <div class="col-12 mt-4">
                                <label class="mt-2" for="country-id">Partner landings </label>
                                <select
                                    id="country-id"
                                    class="form-control"
                                    v-model="domain.landing_id"
                                    :disabled="isEmptyFreePartnerLandings === false"
                                >
                                    <option v-for="land in freePartnerLandings" :key="land.id" :value="land.id">
                                        {{ land.title }}
                                    </option>
                                </select>
                            </div>

                            <hr />
                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="create">
                                        Create
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import api from '@/api'
import { mapActions } from 'pinia'
import { useMessagesStore } from '@/store/messages'

export default {
    name: 'PartnerDomainCreate',
    components: { MasterView, MessageItem, FooterPanel },
    data() {
        return {
            domain: {
                url: null,
                landing_id: null,
            },
            freePartnerLandings: [],
            freePartnerDomains: [],
        }
    },
    computed: {
        isEmptyFreePartnerLandings() {
            return this.freePartnerLandings.length > 0
        },
    },
    methods: {
        ...mapActions(useMessagesStore, ['addMessages', 'clearMessages']),
        cancel() {
            this.$router.go(-1)
        },
        create() {
            api.storePartnerDomain({
                domain: this.domain.url,
                landing_id: this.domain.landing_id,
            }).then((response) => {
                console.log(response.status)
                if (response.status === 204) {
                    this.clearMessages()
                    this.addMessages({
                        title: 'Success',
                        type: 'success',
                        text: 'Domain create successfully',
                        timer: 3,
                    })
                } else {
                    this.clearMessages()
                    this.addMessages({
                        title: 'Error',
                        type: 'error',
                        text: response.response.data.errors[0],
                        timer: 3,
                    })
                }
            })
        },
        load() {
            api.loadFreePartnerLandings().then((response) => {
                this.freePartnerLandings = response.data.data.freePartnerLandings.items
            })
        },
    },
    created() {
        this.load()
    },
}
</script>
