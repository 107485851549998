<template>
    <master-view>
        <remove-modal :modal="removeModal" @cancel="removeModal.reset" @agree="removeLandingItem" />
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2 v-if="status">List general landings (link)</h2>
                        <h2 v-else>Deleted general landings (link)</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- id -->
                                    <th
                                        v-if="!isCurrentUserHasRoleUser"
                                        class="table-header-title"
                                        @click="toggleSort('id')"
                                    >
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>
                                    <!-- author -->
                                    <th class="table-header-title" @click="toggleSort('author')">
                                        author
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('author', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('author', 'asc')"
                                        />
                                    </th>
                                    <!-- image -->
                                    <th class="table-header-title">image</th>
                                    <!-- country -->
                                    <th class="table-header-title" @click="toggleSort('country_id')">
                                        country
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('country_id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('country_id', 'asc')"
                                        />
                                    </th>
                                    <!-- description -->
                                    <th class="table-header-title search-column" @click="toggleSort('description')">
                                        description
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('description', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('description', 'asc')"
                                        />
                                    </th>
                                    <!-- link for desktop -->
                                    <th class="table-header-title col-2" @click="toggleSort('linkForDesktop')">
                                        action button link for desktop
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('linkForDesktop', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('linkForDesktop', 'asc')"
                                        />
                                    </th>
                                    <!-- link for mobile -->
                                    <th class="table-header-title col-2" @click="toggleSort('linkForMobile')">
                                        action button link for mobile
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('linkForMobile', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('linkForMobile', 'asc')"
                                        />
                                    </th>
                                    <!-- title -->
                                    <th class="table-header-title search-column" @click="toggleSort('title')">
                                        title
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('title', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('title', 'asc')"
                                        />
                                    </th>
                                    <!-- type -->
                                    <th class="table-header-title" @click="toggleSort('type')">
                                        type
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('type', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('type', 'asc')"
                                        />
                                    </th>
                                    <!-- deleted -->
                                    <th
                                        class="table-header-title"
                                        @click="toggleSort('deleted_at')"
                                        v-if="isTrashListComponent"
                                    >
                                        deleted
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('deleted_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('deleted_at', 'asc')"
                                        />
                                    </th>
                                    <!-- created -->
                                    <th v-else class="table-header-title" @click="toggleSort('created_at')">
                                        created
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(landing, index) in getLandings.items"
                                    :key="landing.id"
                                    :class="{ 'land-soon-remove': isSoonRemove(landing) }"
                                >
                                    <th scope="row">{{ getAmountNumber(index) }}</th>
                                    <td v-if="!isCurrentUserHasRoleUser">{{ landing['id'] }}</td>
                                    <td>{{ landing.author }}</td>
                                    <td><img class="thumbnail" :src="getImage(landing)" alt="" /></td>
                                    <td>{{ getCountryCode(landing) }}</td>
                                    <td class="cursor-pointer">
                                        <Popper :content="landing.description" title="Click for show full description">
                                            {{ getDescription(landing) }}
                                        </Popper>
                                    </td>
                                    <td>{{ getLinkForDesktop(landing) }}</td>
                                    <td>{{ getLinkForMobile(landing) }}</td>
                                    <td>{{ landing['title'] }}</td>
                                    <td>{{ landing['type'] ?? '-' }}</td>
                                    <td v-if="isTrashListComponent">{{ formatDate(landing.deleted_at) }}</td>
                                    <td v-else>{{ formatDate(landing.created_at) }}</td>
                                    <td class="col-1">
                                        <router-link
                                            v-if="status"
                                            :to="{ name: 'GeneralLinkEdit', params: { id: landing.id } }"
                                        >
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon" />
                                        </router-link>

                                        <font-awesome-icon
                                            v-if="status"
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon"
                                            @click="removeItem(landing.id)"
                                        />

                                        <font-awesome-icon
                                            v-else
                                            :icon="['fas', 'trash-can-arrow-up']"
                                            class="action-icon"
                                            @click="restoreItem(landing.id)"
                                        />

                                        <font-awesome-icon
                                            v-if="status"
                                            :icon="['fas', 'globe']"
                                            class="action-icon"
                                            title="go to resource"
                                            @click="goTo(landing)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import Pagination from '@/components/Layouts/PaginationPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useClientsStore } from '@/store/clients'
import { useCountriesStore } from '@/store/countries'
import { useLandingsStore } from '@/store/landings'
import api from '@/api'
import RemoveModal from '@/components/Modals/RemoveModal.vue'
import Popper from 'vue3-popper'
import user from '@/mixins/user'

export default {
    name: 'GeneralLandingLinkList',
    components: { RemoveModal, Pagination, FooterPanel, MasterView, Popper },
    props: {
        status: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            perPage: 10,
            activeMenuItem: [],
            removeModal: {
                show: false,
                title: null,
                text: null,
                id: null,
                reset: () => {
                    this.removeModal.title = null
                    this.removeModal.text = null
                    this.removeModal.show = false
                },
            },
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            searchObject: {
                text: '',
                active: false,
            },
            type: '',
            currentPage: null,
        }
    },
    computed: {
        ...mapState(useClientsStore, ['getClients']),
        ...mapState(useCountriesStore, ['getCountries']),
        ...mapState(useLandingsStore, ['getLandings', 'getPagination']),
        isSearchActive() {
            return this.searchObject.active
        },
        isCurrentUserHasRoleUser() {
            return this.isUser
        },
        isTrashListComponent() {
            let routeObject = this.$router.options.routes.find((route) => route.name === this.$route.name)
            return routeObject?.name === 'GeneralLandingLinkDeletedList'
        },
    },
    mixins: [user],
    methods: {
        ...mapActions(useCountriesStore, ['loadCountries']),
        ...mapActions(useLandingsStore, ['loadLandings']),
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('landingSearch')
            this.loadLandings({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                action: 'link',
                currentPage: this.currentPage,
            })
        },
        changePage(currentPage) {
            localStorage.setItem('generalLinkCurrentPage', currentPage)
            this.loadLandings({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                action: 'link',
                currentPage: currentPage,
            })
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            if (this.isTrashListComponent) {
                return date.toLocaleDateString()
            } else {
                return date.toLocaleString()
            }
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        isSoonRemove(landing) {
            return landing.soon_remove
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        getCountryCode(landing) {
            if (this.getCountries && this.getCountries.length) {
                return this.getCountries.find((item) => item.id === landing.country_id).code
            }
            return '-'
        },
        getDescription(landing) {
            if (landing.description) {
                return landing.description.slice(0, 30) + '...'
            }
            return '-'
        },
        getLinkForDesktop(landing) {
            if (landing.linkForDesktop) {
                return landing.linkForDesktop.slice(0, 30) + '...'
            }
            return '-'
        },
        getLinkForMobile(landing) {
            if (landing.linkForMobile) {
                return landing.linkForMobile.slice(0, 30) + '...'
            }
            return '-'
        },
        getImage(landing) {
            if (landing.path_to_image) {
                return process.env.VUE_APP_BACKEND_URL + 'storage/' + landing.path_to_image
            }

            return '/images/no_image.png'
        },
        goTo(landing) {
            window.open(process.env.VUE_APP_LAND_RESOURCE + landing.title, '_blank')
        },
        load() {
            this.loadCountries()

            const perPage = localStorage.getItem('landingPerPage')
            const orderBy = localStorage.getItem('GenLandingLinkOrderBy')
            const searchLanding = JSON.parse(localStorage.getItem('landingSearch'))
            const currentPage = localStorage.getItem('generalLinkCurrentPage')
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchLanding && searchLanding.active) {
                this.searchObject.active = searchLanding.active
                this.searchObject.text = searchLanding.text
            }
            if (currentPage) {
                this.currentPage = currentPage
            }
            this.loadLandings({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                action: 'link',
                currentPage: this.currentPage,
            })
        },
        onChangePerPage() {
            localStorage.setItem('perPage', this.perPage)
            this.loadLandings({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                action: 'link',
                currentPage: this.currentPage,
            })
        },
        removeItem(id) {
            this.removeModal.title = 'Remove landing'
            this.removeModal.text = 'Do you want remove this Item ?'
            this.removeModal.show = true
            this.removeModal.id = id
        },
        restoreItem(id) {
            api.restoreLanding(id).then((response) => {
                if (204 === response.status) {
                    this.load()
                }
            })
        },
        removeLandingItem(landing) {
            api.removeLanding(landing.id).then((response) => {
                if (204 === response.status) {
                    this.removeModal.reset()
                    this.load()
                }
            })
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('landingSearch', JSON.stringify(this.searchObject))
                this.loadLandings({
                    orderBy: this.orderBy,
                    perPage: this.perPage,
                    search: this.searchObject.text,
                    status: this.status,
                    action: 'link',
                    currentPage: this.currentPage,
                })
            }
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('GenLandingLinkOrderBy', JSON.stringify(this.orderBy))
            this.loadLandings({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                action: 'link',
                currentPage: this.currentPage,
            })
        },
    },
    created() {
        this.load()
    },
    beforeRouteUpdate(to, from, next) {
        if (from.name === to.name) {
            this.load()
        }
        next()
    },
    watch: {
        status(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.load()
            }
        },
    },
}
</script>
