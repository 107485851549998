import axios from 'axios'
import cookies from 'vue-cookies'
import router from '@/router'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + 'api'
axios.defaults.headers.common['Accept-Language'] = process.env.VUE_APP_LANGUAGE === 'ru' ? 'ru' : 'en'

axios.interceptors.request.use((config) => {
    const token = cookies.get('auth_token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

function handleApiError(error) {
    console.log(error)
    const status = error.response ? error.response.status : null

    if (status === 401) {
        // Redirect to log in
        cookies.remove('auth_token')
        router.push('/login')
        return Promise.resolve(error)
    }

    if (status === 404) {
        // Not found
        router.push('/404')
        return Promise.resolve(error)
    }

    if (status === 422) {
        return Promise.resolve(error)
    }

    if (status === 500) {
        // Server error
        router.push('/500')
        return Promise.resolve(error)
    }

    // Other errors
    // return Promise.reject(error)
}

export default {
    // Auth
    login(data) {
        return axios.post('/admin/login', data).catch(handleApiError)
    },

    // Clients
    clientList(params) {
        let getParams = ''
        if (params.orderBy) {
            getParams = '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        return axios.get('admin/clients' + getParams).catch(handleApiError)
    },
    removeClient(id) {
        return axios.delete('admin/client/' + id).catch(handleApiError)
    },

    // Countries
    changeCurrentCurrency(params) {
        return axios.patch('admin/country/currency', params).catch(handleApiError)
    },

    // Domains
    domainList(params) {
        let getParams = ''
        if (params.orderBy) {
            getParams = '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        return axios.get('admin/domains' + getParams).catch(handleApiError)
    },
    getDomainById(id) {
        return axios.get('admin/domain/' + id).catch(handleApiError)
    },
    getAllCountries() {
        return axios.get('admin/countries').catch(handleApiError)
    },
    storeDomain(domain) {
        return axios.post('admin/domain', domain).catch(handleApiError)
    },
    removeDomain(id) {
        return axios.delete('admin/domain/' + id).catch(handleApiError)
    },
    currentDomainToggle(params) {
        return axios.patch('admin/domain/current', params).catch(handleApiError)
    },
    updateDomain(id, params) {
        return axios.patch('admin/domain/' + id, params).catch(handleApiError)
    },

    // Landing
    landingStore(params) {
        return axios.post('/admin/landing', params).catch(handleApiError)
    },
    loadPartnerClients(params) {
        let getParams = ''
        // Default values
        getParams += '?status=' + params.status

        if (params.orderBy) {
            if (getParams.length) {
                getParams += '&orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
            } else {
                getParams += '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
            }
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        return axios.get('/admin/partners' + getParams).catch(handleApiError)
    },
    landingPartnerStore(params) {
        return axios.post('/admin/partner', params).catch(handleApiError)
    },
    landingPartnerUpdate(params) {
        return axios.patch('/admin/partner', params)
    },
    landingArchiveUpdate(params) {
        return axios.post('/admin/landing/archive-update', params).catch(handleApiError)
    },
    landingPartnerArchiveUpdate(params) {
        return axios.post('/admin/partner/landing/archive-update', params)
    },
    landingUpdate(params) {
        return axios.patch('/admin/landing', params).catch(handleApiError)
    },
    landingList(params) {
        let getParams = ''
        // Default values
        getParams += '?status=' + params.status

        if (params.orderBy) {
            if (getParams.length) {
                getParams += '&orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
            } else {
                getParams += '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
            }
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        if (params.action) {
            return axios.get('admin/landings/' + params.action + getParams).catch(handleApiError)
        }
        return axios.get('admin/landings' + getParams).catch(handleApiError)
    },
    loadPartnerLandings(params) {
        let getParams = ''
        // Default values
        getParams += '?status=' + params.status

        if (params.orderBy) {
            if (getParams.length) {
                getParams += '&orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
            } else {
                getParams += '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
            }
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }

        return axios.get('admin/partner/list' + getParams).catch(handleApiError)
    },
    removeLanding(id) {
        return axios.delete('admin/landing/' + id).catch(handleApiError)
    },
    restoreLanding(id) {
        return axios.patch('admin/landing/' + id).catch(handleApiError)
    },
    landing(id) {
        return axios.get('admin/landing/' + id).catch(handleApiError)
    },

    // Currencies
    currenciesList(params) {
        let getParams = ''
        if (params.orderBy) {
            getParams = '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        if (params.action) {
            return axios.get('admin/currencies/' + params.action + getParams).catch(handleApiError)
        }
        return axios.get('admin/currencies' + getParams).catch(handleApiError)
    },

    // Users
    userList(params) {
        let getParams = ''
        if (params.orderBy) {
            getParams = '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        return axios.get('admin/users' + getParams).catch(handleApiError)
    },
    removeUser(id) {
        return axios.delete('admin/user/' + id).catch(handleApiError)
    },
    storeUser(user) {
        return axios.post('admin/user', user)
    },
    getCurrentUser() {
        return axios.get('admin/user').catch(handleApiError)
    },
    loadUserById(id) {
        return axios.get('admin/user/' + id).catch(handleApiError)
    },
    updateUser(params) {
        return axios.patch('/admin/user', params).catch(handleApiError)
    },
    userRolesChange(id, params) {
        return axios.patch('/admin/user/' + id + '/roles', params).catch(handleApiError)
    },
    getAdministrationMenu() {
        return axios.get('admin/administration/menu')
    },

    // Roles
    rolesList(params) {
        let getParams = ''
        if (params.orderBy) {
            getParams = '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        return axios.get('admin/roles/' + getParams).catch(handleApiError)
    },
    storeRole(params) {
        return axios.post('admin/role', params).catch(handleApiError)
    },
    removeRole(id) {
        return axios.delete('admin/role/' + id).catch(handleApiError)
    },
    getRole(id) {
        return axios.get('admin/role/' + id).catch(handleApiError)
    },
    rolePermissionsChange(id, params) {
        return axios.patch('/admin/role/' + id + '/permissions', params).catch(handleApiError)
    },
    updateRole(params) {
        return axios.patch('/admin/role', params).catch(handleApiError)
    },

    // Permissions
    getPermission(id) {
        return axios.get('admin/permission/' + id).catch(handleApiError)
    },
    permissionsList(params) {
        let getParams = ''
        if (params.orderBy) {
            getParams = '?orderBy=' + params.orderBy.column + ',' + params.orderBy.direction
        }
        if (params.perPage) {
            if (getParams.length) {
                getParams += '&perPage=' + params.perPage
            } else {
                getParams += '?perPage=' + params.perPage
            }
        }
        if (params.search) {
            if (getParams.length) {
                getParams += '&search=' + params.search
            } else {
                getParams += '?search=' + params.search
            }
        }
        if (params.currentPage) {
            if (getParams.length) {
                getParams += '&currentPage=' + params.currentPage
            } else {
                getParams += '?currentPage=' + params.currentPage
            }
        }
        return axios.get('admin/permissions/' + getParams).catch(handleApiError)
    },
    removePermission(id) {
        return axios.delete('admin/permission/' + id).catch(handleApiError)
    },
    storePermission(params) {
        return axios.post('admin/permission', params).catch(handleApiError)
    },
    updatePermission(params) {
        return axios.patch('admin/permission/' + params.id, params).catch(handleApiError)
    },

    // Partner domain
    loadFreePartnerLandings() {
        return axios.get('admin/partner/landings/free').catch(handleApiError)
    },
    loadPartnerDomains(params = {}) {
        const queryParams = new URLSearchParams()

        if (params.free) {
            queryParams.append('free', 'true')
        }

        if (params.landings) {
            queryParams.append('landing', 'true')
        }

        Object.entries(params).forEach(([key, value]) => {
            if (!['free', 'landings'].includes(key)) {
                queryParams.append(key, value)
            }
        })

        const url = `admin/partner/domain${queryParams.toString() ? `?${queryParams.toString()}` : ''}`

        return axios.get(url).catch(handleApiError)
    },
    removePartnerLanding(id) {
        return axios.delete('admin/partner/landing/' + id).catch(handleApiError)
    },
    storePartnerDomain(params) {
        return axios.post('admin/partner/domain', params).catch(handleApiError)
    },
    getPartnerDomainByLandingId(id) {
        return axios.get('admin/partner/domain/' + id).catch(handleApiError)
    },

    // Up
    up() {
        return axios.get('up').catch(handleApiError)
    },
}
