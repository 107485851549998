<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Edit role</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="section">
                                <div class="section-title">General</div>
                                <div class="col-12 mt-4">
                                    <label for="name" class="form-field-title">
                                        Role name <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="name" v-model="role.name" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="description" class="form-field-title">Description</label>
                                    <textarea
                                        class="form-control"
                                        id="description"
                                        v-model="role.description"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="section">
                                <div class="section-title">
                                    Permissions <small>(!) changes will be saved immediately</small>
                                </div>
                                <div class="d-flex mt-4">
                                    <div class="col-6 columns-list">
                                        <div class="row pl-3">Selected</div>
                                        <draggable
                                            v-model="selectedPermissions"
                                            group="roles"
                                            item-key="id"
                                            @end="updatePermissions"
                                        >
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>

                                    <div class="col-6 columns-list">
                                        <div class="row pl-3">Unselected</div>
                                        <draggable
                                            v-model="unselectedPermissions"
                                            group="roles"
                                            item-key="id"
                                            @end="updatePermissions"
                                        >
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>
                                </div>
                            </div>

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="update">
                                        Update
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MasterView from '@/views/Admin/MasterView.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import validation from '@/mixins/validation'
import api from '@/api'
import user from '@/mixins/user'
import draggable from 'vuedraggable'
import { useUserStore } from '@/store/user'

export default {
    name: 'RoleEdit',
    components: { draggable, FooterPanel, MessageItem, MasterView },
    data() {
        return {
            pluckPermissionsArray: [],
            selectedPermissions: [],
            unselectedPermissions: [],
            role: {},
            validateRules: {
                name: { message: 'Field name is required' },
                slug: { message: 'Field slug is required' },
            },
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
        ...mapState(useUserStore, ['getUser']),
    },
    mixins: [validation, user],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages', 'loadUser']),
        cancel() {
            this.$router.go(-1)
        },
        load() {
            api.getCurrentUser().then((currentUserResponse) => {
                const authUserRolePriority = currentUserResponse.data.data.user.roles[0].priority

                api.getRole(this.$route.params.id).then((roleResponse) => {
                    this.role = roleResponse.data.data.role

                    if (this.role.priority >= authUserRolePriority) {
                        this.selectedPermissions = roleResponse.data.data.role.permissions

                        this.pluckPermissionsArray = roleResponse.data.data.role.permissions.map(
                            (permission) => permission.slug
                        )

                        api.permissionsList({ perPage: 1000 }).then((permissionsListResponse) => {
                            this.unselectedPermissions = permissionsListResponse.data.data.permissions.items.filter(
                                (permission) => !this.pluckPermissionsArray.includes(permission.slug)
                            )
                        })
                    } else {
                        this.$router.go(-1)
                    }
                })
            })
        },
        update() {
            api.updateRole(this.role).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    this.addMessages({
                        title: 'Success',
                        type: 'success',
                        text: 'Role updated successfully',
                        timer: 3,
                    })
                    setTimeout(() => {
                        this.$router.push({ name: 'RoleList' })
                    }, 3000)
                } else {
                    if (response.status === 422) {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: response.response.data.errors[0],
                            timer: 3,
                        })
                    } else {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: 'Role not updated',
                            timer: 3,
                        })
                    }
                }
            })
        },
        updatePermissions() {
            const selectedPermissionIds = this.selectedPermissions.map((permission) => permission.id)

            api.rolePermissionsChange(this.$route.params.id, { permissions: selectedPermissionIds })
        },
    },
    created() {
        this.load()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>
