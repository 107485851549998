<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Partner landing edit</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-12 mt-4">
                                <label for="title" class="form-field-title">
                                    Title landing <span class="required">*</span>
                                </label>
                                <input type="text" class="form-control" id="title" v-model="landing.title" />
                            </div>

                            <div class="col-12 mt-4">
                                <label v-if="!isLoading" for="file-input" class="form-field-title">
                                    Select zip archive with new lending page <span class="required">*</span>
                                </label>
                                <label v-else for="file-input" class="form-field-title">Uploading, please wait</label>
                                <div class="d-flex p-0" id="file-input">
                                    <div class="col-md-2 p-0 d-inline-block">
                                        <input
                                            type="file"
                                            class="input_file"
                                            ref="archive"
                                            accept=".zip"
                                            @change="handleArchiveFile"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-outline-primary"
                                            :disabled="isLoading"
                                            @click="$refs.archive.click()"
                                        >
                                            Archive
                                        </button>
                                    </div>
                                    <div class="col-md-2 pt-2">{{ getFilename }}</div>
                                </div>
                            </div>

                            <div class="col-12 mt-4">
                                <label for="description" class="form-field-title">Description</label>
                                <textarea
                                    id="description"
                                    class="form-control"
                                    rows="3"
                                    v-model="landing.description"
                                ></textarea>
                            </div>

                            <hr />

                            <div class="col-12 mt-4">
                                <label for="country-id" class="form-field-title">Partner domain</label>
                                <select id="country-id" class="form-control" v-model="partnerDomainId">
                                    <option v-for="domain in partnerDomains" :key="domain.id" :value="domain.id">
                                        {{ domain.domain }}
                                    </option>
                                </select>
                            </div>

                            <hr />

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="update">
                                        Update
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                    <VueSpinner v-if="isLoading" size="20" color="#5A738E" class="spinner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import api from '@/api'
import validation from '@/mixins/validation'
import { useLandingsStore } from '@/store/landings'

export default {
    name: 'PartnerLandingEdit',
    components: { MessageItem, MasterView, FooterPanel },
    data() {
        return {
            archive: null,
            landing: {
                description: null,
                title: null,
            },
            partnerDomainId: null,
            partnerDomains: {},
            validateRules: {
                title: { message: 'Field title must be at least 3 characters long' },
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
        getFilename() {
            return this.archive ? this.archive.name : ''
        },
    },
    mixins: [validation],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        ...mapState(useLandingsStore, ['getLanding']),
        cancel() {
            this.$router.go(-1)
        },
        handleArchiveFile(event) {
            this.archive = event.target.files[0]
        },
        load() {
            api.landing(this.$route.params.id).then((response) => {
                this.landing = response.data.data.landing
                let params = {
                    free: false,
                }

                api.getPartnerDomainByLandingId(response.data.data.landing.id).then((response) => {
                    this.partnerDomainId = response.data.data.partnerDomain?.id
                    if (this.partnerDomainId === undefined) {
                        params.free = true
                        this.partnerDomainId = null
                    }

                    api.loadPartnerDomains(params).then((response) => {
                        const allDomains = response.data.data.partnerDomains
                        this.partnerDomains = allDomains.filter(
                            (domain) => domain.landing_id === null || domain.id === this.partnerDomainId
                        )
                    })
                })
            })
        },
        async update() {
            try {
                if (this.archive && this.partnerDomainId === null) {
                    throw new Error('Error: partner domain cannot be null when archive is present')
                }

                await api.landingPartnerUpdate({ ...this.landing, partner_domain_id: this.partnerDomainId })

                if (this.archive) {
                    const archive = new FormData()
                    archive.append('id', this.landing.id)
                    archive.append('archive', this.archive)
                    archive.append('partnerDomainId', this.partnerDomainId)

                    await api.landingPartnerArchiveUpdate(archive)
                }

                this.addMessages({
                    title: 'Success',
                    type: 'success',
                    text: 'Landing was updated',
                    timer: 3,
                })

                setTimeout(() => {
                    this.$router.push({ name: 'PartnerLandingList' })
                }, 3000)
            } catch (error) {
                this.addMessages({
                    title: 'Error',
                    type: 'error',
                    text: error.message || 'Landing not updated',
                    timer: 5,
                })
            }
        },
    },
    created() {
        this.load()
    },
}
</script>
